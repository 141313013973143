import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios'
import TrainerNavbar from './trainer_navbar';
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Material from '../material';
import Material2 from '../material2';
import SpesenAnwendung from './waterfall_auf';

// CSS direkt im JSX einfügen
const inlineCSS = `
.centered-field {
  margin: 0 auto;
  max-width: 1400px;
  border: 1px none #ccc;
  padding: 20px;
}
`;


function AuftragDetailTrainer() {
  const { auftragsnummer } = useParams();
  const [auftrag, setAuftrag] = useState(null);
  const [projektUmsatzsteuer, setProjektUmsatzsteuer] = useState(null);
  const [projektid, setProjektid] = useState(null);
  const [km, setKM] = useState(null);
  const [ap, setAP] = useState(null); // Zustand für Ansprechpartner-Daten
const[projektdata, setProjektdata] =useState(null);
  const [showModal, setShowModal] = useState(false);
  const [projektap, setProjektap] = useState(false);
  const [checkliste, setCheckliste] =useState(null)
  const[material,setMaterial]= useState([]);
  const[trmaterial,settrMaterial]= useState([]);
  // Hinzufügen einer neuen Funktion zum Öffnen des Modals
  const handleShowModal = () => setShowModal(true);

  // Hinzufügen einer neuen Funktion zum Schließen des Modals
  const handleCloseModal = () => setShowModal(false);

  const handleRechnungErstellenClick = () => {
    // Hier kannst du die Logik für die Rechnungserstellung implementieren
    console.log('Rechnung erstellen...');
    // Zum Beispiel: Führe eine API-Anfrage für die Rechnungserstellung durch
    // ...
  
    // Schließe das Modal nach der Verarbeitung
    setShowModal(false);  // setze setShowModal auf false, um das Modal zu schließen
  };

  // useEffect(() => {
  //   // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
  //   axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`)
  //     .then(response => {
  //       setAuftrag(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching auftrag details:', error);
  //     });
  // }, [auftragsnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird
  useEffect(() => {
    const fetchProjektUmsatzsteuer = async () => {
      try {
        const response = await axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`);
        const projektNummer = response.data.auftrag_projekt;
        setAuftrag(response.data);
        if (projektNummer) {
          const projektResponse = await axios.get(`https://db.xocore.de/projekt/${projektNummer}`);
          setProjektdata(projektResponse.data);
          setProjektUmsatzsteuer(projektResponse.data.projekt_umsatzsteuer);
          setProjektid(projektResponse.data.projekt_id);
          setKM(projektResponse.data.projekt_kmtyp);
          setCheckliste(projektResponse.data.projekt_checkliste)
  
          if (projektResponse.data.projekt_ansprechpartner) {
            const apResponse = await axios.get(`https://db.xocore.de/ap/${projektResponse.data.projekt_ansprechpartner}`);
            setAP(apResponse.data);
            console.log('Ansprechpartner',apResponse.data)
          }

          axios.get(`https://db.xocore.de/2/mt/${projektNummer}`)
          .then(response => {
            setMaterial(response.data);
            console.log('Material-Daten:', response.data);
          })
          axios.get(`https://db.xocore.de/mt/${projektNummer}`)
          .then(response => {
            settrMaterial(response.data);
            console.log('Material-Daten:', response.data);
          })


        } else {
          console.error('Projekt-Nummer nicht gefunden.', auftragsnummer, projektNummer);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };
  
    if (auftragsnummer) {
      fetchProjektUmsatzsteuer();
    }
  }, [auftragsnummer]);







  
  if (!auftrag) {
    return <div>Loading...</div>;
  }
  

  

  return (

    <div className="centered-field">
      <style>{inlineCSS}</style>

    <div>

      Termin - Details


      <h2>{auftragsnummer} // </h2>
      <form className="row g-3">
      <div className="col-md-2">
        <label htmlFor="Auftragsnummer" className="form-label">Auftragsnummer</label>
        <input type="text" readonly className="form-control" id="Auftragsnummer" value={auftrag.auftrag_nummer} disabled readonly/>
      </div>
      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">Projektnummer</label>
        <input type="text" readonly className="form-control" id="Projektnummer" value={auftrag.auftrag_projekt}  disabled readOnly/>
      </div>
      <div className="col-md-2">
        <label htmlFor="Einsatzdatum" className="form-label">Einsatzdatum</label>
        <input type="text" readonly className="form-control" id="Einsatzdatum" value={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')} disabled readonly/>
      </div>
      <div className="col-md-6">
      <label htmlFor="Auftraggeber" className="form-label">Auftraggeber</label>
        <input type="text" className="form-control" id="Auftraggeber" value={auftrag.auftrag_auftraggeber} disabled readonly/>
      </div>
      <div className="col-6">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input type="text" className="form-control" id="Thema" value={auftrag.auftrag_thema} disabled readonly/>
      </div>

      <div className="col-md-6">
        <label htmlFor="Beschreibung" className="form-label">Beschreibung</label>
        <input type="text" className="form-control" id="Beschreibung" value={auftrag.auftrag_beschreibung} disabled readonly />
      </div>
      <div className="bg-light border"></div>

      {ap && (
      <div className="col-10">
        <label htmlFor="Ansprechpartner" className="form-label"> Ansprechpartner</label>
        <input type="text" className="form-control" id="Ansprechpartner" value={ap.ansprechpartner_name} disabled readonly />
      </div>)}
      
      <div className="col-md-2">
        <label htmlFor="Umfang" className="form-label">Checkliste</label>
        <input type="text" className="form-control" id="Umfang" value={checkliste} disabled readonly  />
      </div>
      {ap && (
      <div className="col-md-6">
        <label htmlFor="EMail" className="form-label">E-Mail</label>
        <input type="text" className="form-control" id="EMail" value={ap.ansprechpartner_mail} disabled readonly/>
      </div>)}{ap && (
      <div className="col-6">
        <label htmlFor="Telefon" className="form-label">Telefon</label>
        <input type="text" className="form-control" id="Telefon" value={ap.ansprechpartner_telefon} disabled readonly/>
      </div>)}
      <div className="bg-light border"></div>

      <div className="col-md-4">
        <label htmlFor="inputState" className="form-label">Umsatzsteuer</label>
        <select id="inputState" className="form-select"  value={projektUmsatzsteuer} disabled readonly >
        <option value="0">Umsatzsteuerfrei</option>
          <option value="1">Umsatzsteuerpflichtig</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="inputState" className="form-label">Durchführungsart</label>
        <select id="inputState" className="form-select" value={projektUmsatzsteuer} disabled readonly >
        <option value="1">Präsenz</option>
          <option value="0">Digital</option>
        </select>
      </div>
      <div className="col-md-2">
        <label htmlFor="inputState" className="form-label">Fahrtkostenregelung</label>
        <select name="km" id="km" className="form-select" value={km} disabled readonly >
        <option value="0.30">0,30 EUR</option>
          <option value="0.45">0,45 EUR</option>
          <option value="1.00">Pauschal</option>
        </select>
      </div>
      <div className="col-md-6">
        <label htmlFor="Trainername" className="form-label">Trainername</label>
        <input type="text" className="form-control" id="Trainername"  value={auftrag.auftrag_trainer} disabled readonly />
      </div>
      <div className="col-md-2">
        <label htmlFor="Tagessatz" className="form-label">Tagessatz</label>

        <input type="text" className="form-control" id="Tagessatz" value={auftrag.auftrag_trainer_tagessatz_zahl} disabled readonly />
      </div>
      <div className="col-md-2">
        <label htmlFor="Umfang" className="form-label">Umfang</label>
        <input type="text" className="form-control" id="Umfang" value={auftrag.auftrag_umfang} disabled readonly  />
      </div>
      <div className="col-md-12">
        <label htmlFor="Beschreibung" className="form-label">Zusatzinfo</label>
        <input type="text" className="form-control" id="Beschreibung" value={auftrag.auftrag_zusatzinfo} disabled readonly />
      </div>
      <div className="bg-light border"></div>

      <div className="col-12">
      <Link to={`/checkliste/${auftragsnummer} `}>   <button type="submit" className="btn btn-danger">Checkliste</button>   </Link>   <Link to={`/tr/dateiansicht/${auftrag.auftrag_projekt}`}>    <button type="submit" className="btn btn-danger">Dateien   </button>    </Link>    <Link to={`/auftragaendern/${auftragsnummer}`}>    <button type="submit" className="btn btn-danger">Ändern   </button></Link> 
              </div>
              <div className="col-12">
              <Link to={`/tr/spesen/${auftragsnummer} `}>    <button type="submit" className="btn btn-danger w-25">Spesenabrechnung</button>  </Link>       <button type="button" className="btn btn-secondary" onClick={handleShowModal}>Rechnung erstellen   </button>   
              </div>
              <Accordion defaultActiveKey="0">

              <Accordion.Item eventKey="2">
        <Accordion.Header>Spesen in Abrechnung</Accordion.Header>
                <Accordion.Body>

        <div className="list-group">


                 {material.map(materialItem => (
        <Fragment key={materialItem.material_id}>
          <Material2
            material={materialItem.name}
            anzahl={`${materialItem.anzahl} `}
            icon={materialItem.abgerechnet}
            material_id={materialItem.material_id}

          />
        </Fragment>
      ))}
            </div>  
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Trainingsmaterial</Accordion.Header>
                <Accordion.Body>

        <div className="list-group">


                 {trmaterial.map(materialItem => (
        <Fragment key={materialItem.material_id}>
          <Material
            material={materialItem.name}
            anzahl={`${materialItem.anzahl}`}
            icon={materialItem.abgerechnet}

          />
          
        </Fragment>
      ))}
            </div>  
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>


    

    </form>
        {/* Neuer Code für das Modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Rechnung erstellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bitte wählen Sie die Abrechnungsart:</p>
          {/* Hier kannst du weitere Inhalte des Modals hinzufügen */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" style={{ width: '45%', marginRight: '5px' }} onClick={handleCloseModal}>
            Einzelabrechnung
          </Button>
          <Button variant="primary" style={{ width: '45%', marginRight: '5px' }} onClick={handleRechnungErstellenClick}>

            Sammelabrechnung
          </Button>
        </Modal.Footer>

      </Modal>

    </div>
    </div>
  );
}

export default AuftragDetailTrainer;