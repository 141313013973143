import React from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoffButton from '../logoff';
class TrainerNavbar extends React.Component {
    state = {}

    render() {
        return (
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="/">
                        <img src="/img/logo_afpuk.png" width="50%" alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/tr/dashbard/" className="active">Home</Nav.Link>
                            <NavDropdown title="Aufträge" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/tr/auftraege">Meine aktuellen Aufträge</NavDropdown.Item>
                            <NavDropdown.Item href="/tr/projekt">Meine Projekte</NavDropdown.Item>
                            <NavDropdown.Item href="/tr/archiv">Alle Aufträge</NavDropdown.Item>

           
              </NavDropdown>
                            <Nav.Link href="/tr/billing//" >Rechnungen</Nav.Link>
                            <Nav.Link href="/tr/projekt" >Spesen</Nav.Link>

                            <Nav.Link href="/tr/einstellungen" >Trainer-Einstellungen</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <LogoffButton></LogoffButton>

                </Container>
            </Navbar>
        );
    }
}

export default TrainerNavbar;
